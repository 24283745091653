<template>
<ul class="feedback-wrapper">
    <li class="feedback-header">
      <p class="feedback-header__title">Наименование документа
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.69966 2.40308C6.28855 2.40308 6.61426 3.08595 6.24366 3.5436L4.33726 5.89782C4.05889 6.24157 3.53545 6.24418 3.25367 5.90322L1.3081 3.549C0.93088 3.09255 1.25554 2.40308 1.84768 2.40308L5.69966 2.40308Z" fill="#5E686C"/>
        </svg>
      </p>
      <p class="feedback-header__title">Дата публикации
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.69966 2.40308C6.28855 2.40308 6.61426 3.08595 6.24366 3.5436L4.33726 5.89782C4.05889 6.24157 3.53545 6.24418 3.25367 5.90322L1.3081 3.549C0.93088 3.09255 1.25554 2.40308 1.84768 2.40308L5.69966 2.40308Z" fill="#5E686C"/>
        </svg>
      </p>
    </li>
    <router-link tag="li" :to="`/feedback/${card.id}`" v-for="(card, i) in data" :key="`card${i}`"  class="feedback-content">
      <p class="feedback-content__text">
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.75 0C0.7875 0 0.0087502 0.765 0.0087502 1.7L0 15.3C0 16.235 0.77875 17 1.74125 17H12.25C13.2125 17 14 16.235 14 15.3V5.1L8.75 0H1.75ZM8.75 5.1V1.275L12.6875 5.1H8.75Z" fill="#246CB7"/>
        </svg>
        {{ card.title }}</p>
      <p class="feedback-content__text">{{ card.date }}</p>
    </router-link>
</ul>

</template>
<script>

export default {
  name: 'FeedbackCardList',

  props: {
    data: {
      type: Array
    }
  },

  methods: {
    getPage (page) {
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.feedback-wrapper {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  .feedback-header{
    display: flex;

    &__title {
      font-size: 0.8125rem;
      line-height: 154%;
      display: flex;
      align-items: center;
      color: #5E686C;
      text-align: left;

      &:nth-child(1){
        width: 60%;
      }
      &:nth-child(2){
        width: 35%;
      }
      svg {
        margin-left: 0.5rem;
      }
    }
  }

  .feedback-content{
    display: flex;
    border: 1px solid #ffffff00;

    &__text{
      font-size: 0.8125rem;
      line-height: 154%;
      display: flex;
      align-items: center;
      color: #5E686C;
      text-align: left;
      margin: 0.625rem 0;
      cursor: pointer;

      &:nth-child(1){
        color: #1F3245;
        font-weight: 600;
        width: 60%;
      }
      &:nth-child(2){
        width: 35%;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
    &:hover{
      margin-left: -1rem;
      padding-left: 1rem;
      border: 1px solid #EBF3FF;
      box-sizing: border-box;
      box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35), 0px 15px 25px rgba(189, 205, 221, 0.35);
      border-radius: 0.5rem;
    }

  }
}

@media screen and (max-width: 768px){
  .feedback-wrapper {
    .feedback-header{

      &__title {
        &:nth-child(1){
          width: 75%;
        }
        &:nth-child(2){
          width: 20%;
        }
      }
    }

    .feedback-content{
      &__text{
        margin: 0.625rem 0;
        cursor: pointer;

        &:nth-child(1){
          width: 75%;
        }
        &:nth-child(2){
          width: 20%;
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
@media screen and (max-width: 420px){
  .feedback-wrapper {
    .feedback-header{
      display: none;
    }
    .feedback-content{
      flex-direction: column;

      &__text{
        &:nth-child(1){
          width: 100%;
        }

        &:nth-child(2){
          display: none;
        }

        svg {
          width: 1.75rem;
          height: 1.75rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
