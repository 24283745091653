<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container feedback">

            <div class="feedback__header">
              <h2>Опросы граждан</h2>
              <div class="polls-wrapper-btn">
                <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnBlock" :class="{'active' : getListView}"></button>
                <button type="button" @click="$store.dispatch('setView', true)" class="btn__icon btnList" :class="{'active' : !getListView}"></button>
              </div>
            </div>

            <div class="feedback__templates">

                <component class="feedback__card" :class="{'feedback__content-list' : getListView}" :is="`feedback-card-${getListView ? 'list' : 'block'}`" :data="$store.getters.getPolls" />

                <!-- <a class="feedback__next">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.51 2.32829L16.7638 0.0138284H10.7531V6.18625L13.0076 3.87106C15.4952 6.42482 15.4952 10.5745 13.0076 13.1282C12.2498 13.908 11.3403 14.4571 10.3784 14.7466V17C11.8899 16.6531 13.3306 15.8835 14.5107 14.6724C17.8299 11.2603 17.8299 5.73962 14.51 2.32829ZM3.99295 13.1282C1.50535 10.5744 1.50535 6.42479 3.99295 3.87102C4.75084 3.09128 5.65962 2.54286 6.62223 2.25337V0C5.11068 0.34694 3.66998 1.11651 2.48991 2.32756C-0.829969 5.73892 -0.829969 11.2596 2.48991 14.6717L0.236031 16.9862H6.24679V10.8137L3.99295 13.1282Z" fill="#246CB7"/>
                  </svg>
                  Показать еще
                </a> -->

            </div>

            <div class="feedback__pagination">
              <p>На странице:  <span>{{ $store.getters.getPolls.length }}</span></p>
              <Pagination v-if="false" :current="1" :total="50" @page-changed="getPage" />
            </div>

        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import FeedbackCardBlock from '@/components/Feedback/FeedbackCardBlock'
import FeedbackCardList from '@/components/Feedback/FeedbackCardList'
import Pagination from '@/components/Pagination'

export default {
  name: 'FeedbackTemplates',
  components: {
    BreadCrumb,
    FeedbackCardBlock,
    FeedbackCardList,
    Pagination
  },
  data () {
    return {
      isActiveTab: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Опросы граждан'
        }
      ]
    }
  },

  mounted () {
    this.$store.dispatch('setView', false)
    // this.$store.dispatch('getListTemplate')
    this.$store.dispatch('setPolls')
  },

  computed: {
    getListView () {
      return this.$store.getters.getListView
    },
    templateRegistry () {
      return this.$store.getters.GET_TEMPLATES
    }
  },

  methods: {
    getPage () {
      console.log('page')
    }
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    width: auto;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.polls-wrapper-btn{
  display: flex;
}

.feedback {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .btn-create {
      padding: 0.45rem 3.125rem;
      margin-left: auto;
      margin-right: 1.25rem;
      background: #EB494E;
      border: 0;
      border-radius: 3px;
      font-weight: bold;
      font-size: 0.85rem;
      color: #FFFFFF;
    }
  }

  &__templates {
    padding: 2rem 0;
    border-bottom: 1px solid #D5D6D9;
    border-top: 1px solid #D5D6D9;
    text-align: center;

  }
  &__card {
    margin-bottom: 1.25rem;
  }

  &__next {
    display: none;
    font-size: 0.875rem;
    line-height: 157%;
    color: #246CB7;
    svg {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 0.5rem;
    }
  }

  &__pagination {
    position: relative;
    margin: 2.5rem 0;
    p {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      margin: auto 0;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 154%;
    }
    span {
      color: #003E78;
    }
  }

  &__content-list {
    flex-wrap: wrap;
    div {
        width: calc(100% - 2.125rem);
    }
  }

}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

  .feedback {
    &__next {
      display: unset;
    }
    &__pagination {
      display: none;
    }
    &__content-list {
      flex-wrap: wrap;
      div {
          width: calc(100% - 2.125rem);
      }
    }
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    h2 {
      margin-bottom: 1.385rem;
    }
    .feedback {
      &__header {
        flex-wrap: wrap;

        .btn-create {
          margin: 0 auto 0 0;
        }
      }

      &__content-list {
        flex-wrap: wrap;
        div {
            width: calc(100% - 2.125rem);
        }
      }
    }
  ::v-deep .feedback-wrapper{
    margin: 0;
    .feedback-block{
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

}
</style>
