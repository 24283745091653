<template>
  <div class="feedback-wrapper">
    <router-link tag="div" :to="`/feedback/${card.id}`" v-for="(card, i) in data" :key="`card${i}`" class="feedback-block">

        <svg width="43" height="59" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.7037 48.0741H7.29626V50.2593H35.7037V48.0741Z" fill="#5A79A8"/>
          <path d="M22.5926 15.2963H7.29626V17.4815H22.5926V15.2963Z" fill="#5A79A8"/>
          <path d="M35.7037 41.5186H7.29626V43.7037H35.7037V41.5186Z" fill="#5A79A8"/>
          <path d="M22.5926 8.74078H7.29626V10.926H22.5926V8.74078Z" fill="#5A79A8"/>
          <path d="M29.1481 0H28.0556H5.11109C2.70733 0 0.740723 1.96661 0.740723 4.37037V54.6296C0.740723 57.0333 2.70733 59 5.11109 59H37.8889C40.2925 59 42.2592 57.0333 42.2592 54.6296V16.3889V15.2964L29.1481 0ZM29.1481 3.27783L39.4186 15.2964H31.3333C30.2408 15.2964 29.1481 14.2039 29.1481 13.1112V3.27783ZM40.0741 54.6296C40.0741 55.7222 39.2 56.8148 37.8889 56.8148H5.11109C4.01856 56.8148 2.92591 55.9407 2.92591 54.6296V4.37037C2.92591 3.27783 3.79998 2.18519 5.11109 2.18519H26.9629V13.1111C26.9629 15.5149 28.9297 17.4815 31.3333 17.4815H40.0741V54.6296Z" fill="#5A79A8"/>
          <path d="M35.7037 34.963H7.29626V37.1481H35.7037V34.963Z" fill="#5A79A8"/>
          <path d="M35.7037 21.8519H7.29626V24.0371H35.7037V21.8519Z" fill="#5A79A8"/>
          <path d="M35.7037 28.4074H7.29626V30.5926H35.7037V28.4074Z" fill="#5A79A8"/>
        </svg>

        <p v-trim="2" class="feedback-block__title">{{card.title}}</p>

    </router-link>
  </div>
</template>
<script>

export default {
  name: 'FeedbackCardBlock',

  props: {
    data: {
      type: Array
    }
  },

  methods: {
    getPage (page) {
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.feedback-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.06rem;

  .feedback-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2.25rem 1.4rem 1.25rem;
    background: #FFFFFF;
    border: 1px solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35), 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 0.5rem;
    width: calc(16.666% - 2.125rem);
    margin: 0 1.06rem 2.125rem 1.06rem;
    cursor: pointer;

    svg{
      width: 3.75rem;
      height: 3.75rem;
      margin-bottom: 1.125rem;
    }

    &__title {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 154%;
      text-align: center;
      color: #1F3245;
      margin-bottom: auto;
    }
  }
}

@media screen and (max-width: 768px){
  .feedback-wrapper {
    .feedback-block{
      width: calc(33.333% - 2.125rem);
    }
  }
}
@media screen and (max-width: 420px){
  .feedback-wrapper {
    .feedback-block{
      width: calc(100% - 2.125rem);
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .feedback-block {
    &__title {
      width: 100%;
    }
  }
}

</style>
